import { Button, Popper, Popover, Box, Stack, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

export const Lines3DLegend = () => {
    const btnRef = useRef<HTMLButtonElement|null>(null);
    const [ opened, setOpened ] = useState(false);
    return (
        <React.Fragment>
            <Button ref={btnRef} id='open-legend-popover-button' color='secondary' onClick={(event) => setOpened(!opened)}>
                Legend
            </Button>
            <Popover
                anchorEl={btnRef.current}
                open={opened}
                onClose={() => setOpened(false)}
                sx={{zIndex: 999999}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Stack padding={'20px'} gap={3} sx={{backgroundColor: 'white', zIndex: 999999, width: '300px'}}>
                    <Stack direction='row' gap={2}>
                        <Box width={15} height={15} sx={{border: '1px solid black', backgroundColor: '#66ff66'}}/>
                        <Typography>Loaded line</Typography>
                    </Stack>
                    <Stack direction='row' gap={2}>
                        <Box width={15} height={15} sx={{border: '1px solid black', backgroundColor: '#ffff66'}}/>
                        <Typography>Loading line</Typography>
                    </Stack>
                    <Stack direction='row' gap={2}>
                        <Box width={15} height={15} sx={{border: '1px solid black', backgroundColor: 'white'}}/>
                        <Typography>Not loaded line</Typography>
                    </Stack>
                    <Stack>
                        <Typography>
                            Transparent tiles in a seismic means that this part is not yet loaded.
                        </Typography>
                    </Stack>
                </Stack>
            </Popover>
        </React.Fragment>
    );
};