import { AppBar, Box, Button, css, IconButton, Menu, MenuItem, Popover, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { Fragment, useState, useContext, useRef, useMemo } from 'react';
import React from 'react';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import { useSessionStore } from 'session/useSessionStore';
import { GeopostLogo } from 'components/geopostLogo/GeopostLogo';
import { use3DViewerStore } from 'features/seismic-3d/stores/use3DViewerStore';
import { GeopostMuiPopup } from 'components/geopostMuiPopup/GeopostMuiPopup';
import { IntersectingSeismicsList } from '../SeismicsList/IntersectingSeismicsList/IntersectingSeismicsList';
import { IntersectingLayersList } from '../LayersList/IntersectingLayersList/IntersectingLayersList';
import { GridSlider } from '../Slider/GridSlider';
import { SelectedSeismicsPopup } from '../SeismicsList/SelectedSeismicsList/SelectedSeismicsPopup';
import { GridsList } from '../GridsList/GridsList';
import { SeismicWellPopup } from 'features/seismic/components/wells/SeismicWellPopup';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';
import { LightIntensitySlider } from '../LightIntensitySlider';
import { OnLogoutContext } from 'features/auth/OnLogoutContext';
import { HeightScaleSlider } from '../HeightScaleSlider';
import { use3DSceneStore } from 'features/seismic-3d/stores/use3DSceneStore';
import { WebTour3D } from '../WebTour3D';
import { AbsoluteGainMenu } from 'features/seismic/components/AbsoluteGainMenu';
import { GeopostPopover } from 'components/GeopostPopover';
import { PerformancePanel } from 'features/seismic/components/performancePanel/PerformancePanel';
import { getUrlParams } from 'features/seismic-3d/utils/Seismic3DUrlUtils';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';
import { use3DFeatureFlags } from 'features/seismic-3d/hooks/useSeismic3DFeatureFlags';
import { Lines3DLegend } from '../Lines3DLegend';

const linkButton = css({
    '& a': {
        color: 'white',
        cursor: 'pointer',
        textDecoration: 'none',
    },
    '& a: visited': {
        color: 'white'
    }
});

export const Seismic3DNavbar = () => {
    const seismic3DFeatureFlags = use3DFeatureFlags();
    const [layersListMenuAnchor, setLayersListMenuAnchor] = useState<null | HTMLElement>(null);
    const [seismicsListMenuAnchor, setSeismicsListMenuAnchor] = useState<null | HTMLElement>(null);
    const [lineMenuAnchor, setLineMenuAnchor] = useState<null | HTMLElement>(null);
    const [gridsMenuAnchor, setGridsMenuAnchor] = useState<null | HTMLElement>(null);

    const [intersectingLayersPopupOpened, setIntersectingLayersPopupOpened] = useState<boolean>(false);
    const [intersectingSeismicsPopupOpened, setIntersectingSeismicsPopupOpened] = useState<boolean>(false);
    const [selectedSeismicsPopupOpened, setSelectedSeismicsPopupOpened] = useState<boolean>(false);
    const [gridSliderPopupOpened, setGridSliderPopupOpened] = useState<boolean>(true);
    const [gridListPopupOpened, setGridListPopupOpened] = useState<boolean>(false);
    const [ wellsPopupOpened, setWellsPopupOpened ] = useState<boolean>(false);
    const [ helpPopoverAnchor, setHelpPopoverAnchor ] = useState<HTMLButtonElement | null>(null);
    const [ gainPopperOpened, setGainPopperOpened ] = useState(false);
    const [gainMenuAnchor, setGainMenuAnchor] = useState<HTMLElement | null>(null);
    const [ performanceMonitoringPopupOpened, setPerformanceMonitoringPopupOpened ] = useState(false);

    const { current: urlParams } = useRef(getUrlParams());

    const mainFeatureGeomData = use3DViewerStore(state => state.mainFeatureGeomData);

    const handleHelpButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (helpPopoverAnchor) {
            setHelpPopoverAnchor(null);
        } else {
            setHelpPopoverAnchor(event.currentTarget);
        }
    };

    const onLogout = useContext(OnLogoutContext);

    const scene = use3DSceneStore(state => state.scene);

    const [ runWebTour, setRunWebTour ] = useState(false);

    const [ runWellsWebTour, setRunWellsWebTour ] = useState(false);

    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const gridSliderPopupHeight = useMemo(() => {
        return seismic3DFeatureFlags?.enableZslice ? '350px' : '260px';
    }, [seismic3DFeatureFlags?.enableZslice]);

    return (
        <Fragment>
            <AppBar position='static' sx={{ height: '64px' }}>
                <Toolbar sx={{width: '100%'}}>
                    <Stack direction='row' spacing={0} marginRight={2}>
                        <GeopostLogo />
                        {
                            seismic3DFeatureFlags?.enableParentAppLink &&
                            tenantConfig
                            &&
                            <Stack>
                                <Button css={linkButton}>
                                    <a target='_blank' href={tenantConfig.parentApplicationHost} title={tenantConfig.parentApplicationName}>{tenantConfig.parentApplicationName}</a>
                                </Button>
                            </Stack>
                        }
                    </Stack>
                    <Stack direction='row' spacing={2}>
                        <Stack>
                            <Tooltip
                                placement='bottom'
                                title={
                                    <Stack><Typography variant='body2'/>Reset the camera to its original position</Stack>
                                }
                            >
                                <IconButton onClick={() => scene?.resetCamera()}>
                                    <RestartAltIcon fontSize='medium' color='secondary'/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <LightIntensitySlider/>
                        <Stack width='240px'>
                            <HeightScaleSlider/>
                        </Stack>
                        {
                            seismic3DFeatureFlags?.enableSurveys
                            &&
                            <Stack>
                                <Button id='open-seismics-popup-button' color='inherit' onClick={(event) => setSeismicsListMenuAnchor(event.currentTarget)} >
                                    Seismics
                                </Button>
                                <Menu MenuListProps={{ onMouseLeave: () => setSeismicsListMenuAnchor(null) }} color='inherit' id='seismics-list-menu' anchorEl={seismicsListMenuAnchor} onClose={() => setSeismicsListMenuAnchor(null)} open={!!seismicsListMenuAnchor}>
                                    <MenuItem
                                        onClick={() => {
                                            setSeismicsListMenuAnchor(null);
                                            setIntersectingSeismicsPopupOpened(true);
                                        }}
                                    >
                                        Intersecting seismics
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setSeismicsListMenuAnchor(null);
                                            setSelectedSeismicsPopupOpened(true);
                                        }}
                                    >
                                        Selected seismics
                                    </MenuItem>
                                </Menu>
                            </Stack>
                        }

                        {
                            seismic3DFeatureFlags?.enableProjections
                            &&
                            <Stack>
                                <Button id='open-projections-popup-button' color='inherit' onClick={(event) => setIntersectingLayersPopupOpened(!intersectingLayersPopupOpened)}>
                                    Layers
                                </Button>
                            </Stack>
                        }
                        <Stack>
                            <Button id='seismic-3d-lines-popup-button' color='inherit' onClick={(event) => setGridSliderPopupOpened(!gridSliderPopupOpened)}>
                                Slider
                            </Button>
                        </Stack>
                        {
                            seismic3DFeatureFlags?.enableGrids
                            &&
                            <Stack>
                                <Button id='open-grids-popup-button' color='inherit' onClick={(event) => setGridListPopupOpened(!gridListPopupOpened)}>
                                    Grids
                                </Button>
                            </Stack>
                        }
                        {
                            seismic3DFeatureFlags?.enableWells
                            &&
                            <Stack>
                                <Button id='open-wells-popup-button' color='inherit' onClick={(event) => setWellsPopupOpened(true)}>
                                    Wells
                                </Button>
                            </Stack>
                        }
                        {
                            seismic3DFeatureFlags?.enablePerformanceMonitoring && urlParams.performanceMonitoring
                            &&
                            <Stack>
                                <Button color='inherit' onClick={() => setPerformanceMonitoringPopupOpened(true)}>
                                    Performance
                                </Button>
                            </Stack>
                        }
                        <Stack>
                            <Button color='inherit' onClick={() => setRunWebTour(true)}>
                                Help
                            </Button>
                            <Popover
                                anchorEl={helpPopoverAnchor}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                                onClose={handleHelpButtonClick}
                                open={Boolean(helpPopoverAnchor)}
                            >
                                <Typography sx={{p: 2}}><b>scroll -</b> zoom</Typography>
                                <Typography sx={{p: 2}}><b>right mouse button -</b> pan</Typography>
                                <Typography sx={{p: 2}}><b>left mouse button -</b> rotate</Typography>
                            </Popover>
                        </Stack>
                        <Stack>
                            <Lines3DLegend/>
                        </Stack>
                        <Stack>
                            <Button color='inherit' onClick={onLogout}>
                                Logout
                            </Button>
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            {mainFeatureGeomData &&
                <GeopostMuiPopup
                    open={intersectingSeismicsPopupOpened}
                    onClose={() => setIntersectingSeismicsPopupOpened(false)}
                    width={'600px'}
                    height={'300px'}
                    title={'Intersecting Seismics'}
                    left={'10px'}
                    top={'200px'}
                >
                    <IntersectingSeismicsList
                        geomSrid='3857'
                        geomWKT={mainFeatureGeomData.GeomWKT}
                    />
                </GeopostMuiPopup>
            }

            {
                mainFeatureGeomData
                &&
                <GeopostMuiPopup
                    open={intersectingLayersPopupOpened}
                    onClose={() => setIntersectingLayersPopupOpened(false)}
                    height={'300px'}
                    width={'320px'}
                    title='Intersecting Layers'
                    left={'1px'}
                    top={'400px'}
                >
                    <Box
                        sx={{
                            p: '1px 5px 1px 1px',
                            overflowX: 'auto'
                        }}
                    >
                        <IntersectingLayersList
                            mainFeatureGeomWkt={mainFeatureGeomData.GeomWKT}
                            mainFeatureGeomSrid={3857}
                        />
                    </Box>
                </GeopostMuiPopup>
            }
            {
                <GeopostMuiPopup
                    id='seismic-3d-lines-popup'
                    open={gridSliderPopupOpened}
                    onClose={() => setGridSliderPopupOpened(false)}
                    height={gridSliderPopupHeight}
                    width={'280px'}
                    title={'Lines'}
                    left={'calc(100vw - 282px)'}
                    top={`calc(100vh - calc(${gridSliderPopupHeight} + 40px))`}
                >
                    <GridSlider />

                </GeopostMuiPopup>
            }
            <SelectedSeismicsPopup
                open={selectedSeismicsPopupOpened}
                onClose={() => setSelectedSeismicsPopupOpened(false)}
                height={'300px'}
                width={'350px'}
                left={'2px'}
                top={'calc(100vh - 300px)'}
            />
            {
                <GeopostMuiPopup
                    open={gridListPopupOpened}
                    onClose={() => setGridListPopupOpened(false)}
                    height={'300px'}
                    width={'450px'}
                    left={'2.5vw'}
                    top={'50vh'}
                    title={'Grids'}
                >
                    <GridsList />
                </GeopostMuiPopup>
            }
            <GeopostMuiPopup
                open={performanceMonitoringPopupOpened}
                onClose={() => setPerformanceMonitoringPopupOpened(false)}
                height='400px'
                width='1300px'
                top={'200px'}
                left='5px'
                title='Performance monitoring'
            >
                <PerformancePanel surveyType={SurveyType.Seismic3D}/>
            </GeopostMuiPopup>
            <SeismicWellPopup opened={wellsPopupOpened} setOpened={setWellsPopupOpened} dataGeometryLine={null} surveyType={SurveyType.Seismic3D} runWebtour={runWellsWebTour} />
            <WebTour3D run={runWebTour} onEnd={() => setRunWebTour(false)}/>
        </Fragment>
    );
};