import { useMutation, useQueries, useQuery } from 'react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axiosRetry from 'axios-retry';

import { useSessionStore } from 'session/useSessionStore';
import { axios } from 'configurations/axios';
import { IResponseAPI } from 'models/interfaces/IResponseAPI';
import { getUrlParams } from 'features/seismic-3d/utils/Seismic3DUrlUtils';
import { SeismicSearchDTO } from 'features/seismic-3d/models/types/SeismicsSearchDTO';
import { Seismic3DData } from 'features/seismic-3d/models/classes/Seismic3DData';
import { FeatureGeom } from 'features/seismic-3d/models/classes/FeatureGeom';
import { LineStringResponse } from 'features/seismic-3d/models/types/LineStringResponse';
import { IGetLineGeometryRequest } from 'features/seismic-3d/models/interfaces/requests/IGetLineGeometryRequest';
import { TileRequest } from 'features/seismic-3d/models/types/TileRequest';
import { VolumeType } from '../models/enums/VolumeType';
import { getLineGeometryPerformanceDataFromAxiosResponse, getPerformanceDataFromAxiosResponse, getSurveyGeometryPerformanceDataFromAxiosResponse, getTilePerformanceDataFromAxiosResponse } from 'features/seismic/utils/performanceUtils';
import { usePerformanceMonitoringStore } from 'features/seismic/stores/usePerformanceMonitoringStore';
import { LineTileRequest } from '../models/types/LineTileRequest';
import { ZSliceTileRequest } from '../models/types/ZSliceTileRequest';
import { useFeatureFlags } from 'features/seismic/hooks/useFeatureFlags';
import { use3DFeatureFlags } from '../hooks/useSeismic3DFeatureFlags';
import { SurveyType } from 'features/seismic/models/enums/SurveyType';

export function useGetSeismicsDataByGeom(callback : (data : Seismic3DData[]) => void){
    const [ seismicUrl, jwtToken ] = useSessionStore(state => [state.tenantConfiguration?.endpoints.metadata, state.jwtToken]);
    return useMutation(async (data : SeismicSearchDTO) => {
        axios.post<IResponseAPI<Seismic3DData[]>>(
            seismicUrl + '/Seismic3DData/GetSeismicsDataByGeom',
            {
                geomWKT: data.geomWKT,
                geomSrid: data.geomSrid,
                seismicDomain: data.seismicDomain,
                SurveyType: data.surveyType
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    external_sso_access_token: 'Bearer ' + getUrlParams().externalSSOAccessToken
                }
            }
        ).then((response) => {console.log(response.data); callback(response.data.Result) });
    },{
        onError: () => {
            console.log('Error when fetching seismics data using geom');
        },
        onSuccess: (data) => {
            console.log(data);
        },
        retry: 3,
    }
    );
}

export function useGetGeomByVolumeToken(volumeToken : string | undefined,){
    let [ seismicUrl, jwtToken ] = useSessionStore(state => [ state.tenantConfiguration?.endpoints.lineGeometry, state.jwtToken ]);

    const { sourceApi, performanceMonitoring } = getUrlParams();

    if (window.location.href.indexOf('gcp-gpost') > -1 && sourceApi === 'tgs-cloud'){
        seismicUrl = 'https://tgs-viewer-seismic-api-default.geopost.datalake.tgs.com';
        volumeToken = 'fc8cbaca-62b7-4de1-886b-b76b03035790';
    }

    const addSurveyGeometryPerformanceData = usePerformanceMonitoringStore(state => state.addSurveyGeometryPerformanceData);

    const enabled = useMemo(() => !!volumeToken, [volumeToken]);

    return useQuery<FeatureGeom>(
        ['GetGeomByVolumeToken', volumeToken],
        async () => {
            const requestSendAt = new Date();
            const response = await axios.get(
                seismicUrl + '/Seismic3DData/GetGeomByVolumeToken',
                {
                    params: {
                        volumeToken: volumeToken,
                        forceDisableCache: getUrlParams().forceDisableCache
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        external_sso_access_token: 'Bearer ' + getUrlParams().externalSSOAccessToken,
                        'x-gp-debug-active': performanceMonitoring
                    }
                }
            );

            if (performanceMonitoring) {
                const performanceData = getSurveyGeometryPerformanceDataFromAxiosResponse(response, requestSendAt, volumeToken!);
                if (!!performanceData) {
                    addSurveyGeometryPerformanceData(performanceData);
                }
            }

            return response.data.Result;
        },
        {
            enabled: enabled
        }
    );
}

export function useGetLineGeometry(params: IGetLineGeometryRequest | undefined) {
    let { seismicUrl, jwtToken} = useSessionStore(state => ({
        seismicUrl: state.tenantConfiguration?.endpoints.lineGeometry,
        jwtToken:state.jwtToken
    }));

    const { sourceApi, performanceMonitoring } = getUrlParams();

    if (window.location.href.indexOf('gcp-gpost') > -1 && sourceApi === 'tgs-cloud'){
        seismicUrl = 'https://tgs-viewer-seismic-api-default.geopost.datalake.tgs.com';
    }
    //xhr.setRequestHeader('external_sso_access_token', 'Bearer ' + getParamsFromUrl().externalSSOAccessToken);
    return useQuery(
        ['getGeometryOfSeismicLine', params],
        async <GridLine>() => {
            const response = await axios.get<IResponseAPI<LineStringResponse>>(
                seismicUrl /*'http://localhost:5000'*/ + '/Seismic/Volume/GetGeometryOfSeismicLine',
                {
                    params: {
                        volumeToken: params?.volumeToken,
                        lineType: params?.lineType,
                        lineNumber: params?.lineNumber,
                        volumeType: params?.volumeType,
                        forceDisableCache: getUrlParams().forceDisableCache,
                        srid: params?.srid,
                        'x-gp-debug-active': performanceMonitoring,
                        dimensionName: params?.dimensionName,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        external_sso_access_token: 'Bearer ' + getUrlParams().externalSSOAccessToken
                    }
                }
            );
            return response.data.Result;
        },
        {
            enabled: !!params,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false
        }
    );
}

export const useGetLineGeometryImperatively = () => {
    let { seismicUrl, jwtToken} = useSessionStore(state => ({
        seismicUrl: state.tenantConfiguration?.endpoints.lineGeometry,
        jwtToken:state.jwtToken
    }));

    const { sourceApi, performanceMonitoring } = getUrlParams();

    if (window.location.href.indexOf('gcp-gpost') > -1 && sourceApi === 'tgs-cloud'){
        seismicUrl = 'https://tgs-viewer-seismic-api-default.geopost.datalake.tgs.com';
    }

    return useMutation(async (params: IGetLineGeometryRequest) => {
        const response = await axios.get<IResponseAPI<LineStringResponse>>(
            seismicUrl /*'http://localhost:5000'*/ + '/Seismic/Volume/GetGeometryOfSeismicLine',
            {
                params: {
                    volumeToken: params?.volumeToken,
                    lineType: params?.lineType,
                    lineNumber: params?.lineNumber,
                    volumeType: params?.volumeType,
                    dimensionName: params?.dimensionName,
                    forceDisableCache: getUrlParams().forceDisableCache,
                    srid: params?.srid,
                    'x-gp-debug-active': performanceMonitoring
                },
                headers: {
                    'Content-Type': 'application/json',
                    external_sso_access_token: 'Bearer ' + getUrlParams().externalSSOAccessToken
                }
            }
        );
        return response.data.Result;
    });
};

export const useGetLineGeometries = (requests: IGetLineGeometryRequest[]) => {
    let [ seismicUrl, jwtToken ] = useSessionStore(state => [ state.tenantConfiguration?.endpoints.lineGeometry, state.jwtToken ]);
    const { sourceApi, performanceMonitoring } = getUrlParams();
    const addLineGeometryPerformanceData = usePerformanceMonitoringStore(state => state.addLineGeometryPerformanceData);

    let volumeToken = '';
    if (requests.length > 0){
        volumeToken = requests[0].volumeToken;
        if (window.location.href.indexOf('gcp-gpost') > -1 && sourceApi === 'tgs-cloud'){
            seismicUrl = 'https://tgs-viewer-seismic-api-default.geopost.datalake.tgs.com';
            volumeToken = 'fc8cbaca-62b7-4de1-886b-b76b03035790';
        }
    }

    const [ lineGeometries, setLineGeometries ] = useState<(LineStringResponse | undefined)[]>([]);

    const currentLineGeometriesRef = useRef<(LineStringResponse | undefined)[]>([]);

    const updateLineGeometry = useCallback(async (request: IGetLineGeometryRequest, index: number) => {
        const requestLeaveAt = new Date();
        const response = await axios.get<IResponseAPI<LineStringResponse>>(
            seismicUrl /*'http://localhost:5000'*/ + '/Seismic/Volume/GetGeometryOfSeismicLine',
            {
                params: {
                    volumeToken: volumeToken,
                    lineType: request.lineType,
                    lineNumber: request.lineNumber,
                    forceDisableCache: getUrlParams().forceDisableCache,
                    volumeType: VolumeType.Seismics3D,
                    srid: request.srid,
                    dimensionName: request.dimensionName,
                },
                headers: {
                    Authorization: `Bearer ` + jwtToken,
                    'Content-Type': 'application/json',
                    'x-gp-debug-active': performanceMonitoring
                }
            }
        );
        if (performanceMonitoring) {
            const performanceData = getLineGeometryPerformanceDataFromAxiosResponse(response, requestLeaveAt, request.lineNumber, request.lineType);
            if (!!performanceData) {
                addLineGeometryPerformanceData(performanceData);
            }
        }
        setLineGeometries(current => {
            const newArray = [...current];
            newArray[index] = response.data.Result;
            //console.log('newArray line geometries');
            //console.log(newArray);
            console.log('a');
            return newArray;
        });
    }, [jwtToken, seismicUrl, volumeToken]);

    const updateLineGeometries = useCallback(async () => {
        for (let i = 0; i < requests.length; i++) {
            updateLineGeometry(requests[i], i);
        }
    }, [requests, updateLineGeometry]);

    useEffect(() => {
        axiosRetry(axios, { retries: 3 });
        setLineGeometries(requests.map(() => undefined));
        updateLineGeometries();
    }, [requests]);

    return lineGeometries;
};

export type TileResult = {
    file: File | null,
    error: boolean,
};

export const useGetTile = (surveyType: SurveyType) => {
    const [ renderUrl, jwtToken ] = useSessionStore(state => [
        (surveyType === SurveyType.Seismic2D ? state.tenantConfiguration?.endpoints.render : state.tenantConfiguration?.endpoints.render3D),
        state.jwtToken
    ]);
    const { requestMsOverhead: overhead, performanceMonitoring: performanceMonitoringParam } = getUrlParams();

    const addTilesPerformanceData = usePerformanceMonitoringStore(state => state.addTilePerformanceData);

    return useMutation({
        mutationFn: async (tileRequest: LineTileRequest) => {
            const urlSearchParams = new URLSearchParams();
            Object.entries(tileRequest).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    urlSearchParams.append(key, value.toString());
                }
            });
            const requestSendAt = new Date();
            const response = await axios.post<Blob>(
                renderUrl + '/seismic/render/tile',
                urlSearchParams,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        external_sso_access_token: 'Bearer ' + getUrlParams().externalSSOAccessToken,
                        'x-gp-debug-active': performanceMonitoringParam,
                        Authorization: 'Bearer ' + jwtToken
                    },
                    responseType: 'blob'
                }
            );
            if (performanceMonitoringParam) {
                const performanceData = getTilePerformanceDataFromAxiosResponse(response, requestSendAt, tileRequest.lineNumber, tileRequest.lineType, tileRequest.tileWidth);
                if (!!performanceData) {
                    addTilesPerformanceData(performanceData);
                }
            }
            return response.data;
        }
    });
};

export const useGetTiles = (tileRequests : LineTileRequest[]) => {

    const [ renderUrl, jwtToken ] = useSessionStore(state => [state.tenantConfiguration?.endpoints.render, state.jwtToken]);
    const [ tileResults, setTileResults ] = useState<(TileResult | undefined)[]>(tileRequests.map(x => undefined));
    const { requestMsOverhead: overhead, performanceMonitoring: performanceMonitoringParam } = getUrlParams();

    const addTilesPerformanceData = usePerformanceMonitoringStore(state => state.addTilePerformanceData);

    useEffect(() => {
        axiosRetry(axios, { retries: 3 });
        setTileResults(tileRequests.map(x => undefined));
        tileRequests.forEach((tileRequest, index) => {
            const urlSearchParams = new URLSearchParams();
            Object.entries(tileRequest).forEach(([key, value]) => {
                if (value !== null && value !== undefined) {
                    urlSearchParams.append(key, value.toString());
                }
            });
            setTimeout(() => {
                const requestSendAt = new Date();
                axios.post(
                    renderUrl + '/seismic/render/tile',
                    urlSearchParams,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                            external_sso_access_token: 'Bearer ' + getUrlParams().externalSSOAccessToken,
                            'x-gp-debug-active': performanceMonitoringParam
                        },
                        responseType: 'blob'
                    }
                ).then(response => {
                    setTileResults(current => {
                        const newTilesArray = [...current];
                        newTilesArray[index] = { file: response.data, error: false };
                        return newTilesArray;
                    });
                    if (performanceMonitoringParam) {
                        const performanceData = getTilePerformanceDataFromAxiosResponse(response, requestSendAt, tileRequest.lineNumber, tileRequest.lineType, tileRequest.tileWidth);
                        if (!!performanceData) {
                            addTilesPerformanceData(performanceData);
                        }
                    }
                }).catch(error => {
                    setTileResults(current => {
                        const newTilesArray = [...current];
                        newTilesArray[index] = { file: null, error: true };
                        return newTilesArray;
                    });
                });
            }, overhead * index);
        });
    }, [tileRequests]);

    return tileResults;
};

export const useGetZSliceTile = (surveyType: SurveyType) => {
    const [ renderUrl, jwtToken ] = useSessionStore(state => [
        (surveyType === SurveyType.Seismic2D ? state.tenantConfiguration?.endpoints.render : state.tenantConfiguration?.endpoints.render3D),
        state.jwtToken
    ]);

    return useMutation({
        mutationFn: async (params: ZSliceTileRequest) => {
            const response = await axios.post<Blob>(renderUrl +'/seismic/render/zslice', params, {
                headers: {
                    Authorization: 'bearer ' + jwtToken,
                    'Content-Type': 'application/json'
                },
                responseType: 'blob'
            });
            return response.data;
        }
    });
};

export function useGetSeismicDataByVolumeToken(volumeToken: string | undefined, volumeIdentifier: string | undefined) {
    let [ seismicUrl, jwtToken ] = useSessionStore(state => [ state.tenantConfiguration?.endpoints.metadata, state.jwtToken ]);

    //seismicUrl = seismicUrl?.replace('render', 'api');

    const { sourceApi } = getUrlParams();

    if (window.location.href.indexOf('gcp-gpost') > -1 && sourceApi === 'tgs-cloud'){
        seismicUrl = 'https://tgs-viewer-seismic-api-default.geopost.datalake.tgs.com';
    }

    return useQuery<Seismic3DData>(
        ['GetSeismicDataByVolumeToken', volumeToken],
        async () => {
            const response = await axios.get(
                seismicUrl + '/Seismic3DData/GetSeismicDataByVolumeToken',
                {
                    params: {
                        volumeToken: volumeToken,
                        forceDisableCache: getUrlParams().forceDisableCache,
                        volumeIdentifier: volumeIdentifier
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        external_sso_access_token: 'Bearer ' + getUrlParams().externalSSOAccessToken
                    }
                }
            );
            return response.data.Result;
        },
        {
            enabled: !!volumeToken && !!volumeIdentifier
        }
    );
}

